/* La clase `LayoutsModule` es un módulo en una aplicación Angular que declara y exporta varios
componentes de diseño e importa los módulos y dependencias necesarios. */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthLayoutComponent} from './auth-layout/auth-layout.component';
import {FooterComponent} from './footer/footer.component';
import {ContentLayoutComponent} from './content-layout/content-layout.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {RouterModule} from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {SidebarModule} from 'ng-sidebar';
import {PanelComponent} from './panel/panel.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SharedModule} from '@shared/shared.module';
import {LoadingComponent} from '@shared/components/loading/loading.component';

@NgModule({
  declarations: [
    AuthLayoutComponent,
    FooterComponent,
    ContentLayoutComponent,
    SidebarComponent,
    NavbarComponent,
    PanelComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    SidebarModule,
    MatSidenavModule,
    SharedModule
  ],
  exports: [
    LoadingComponent
  ]
})
export class LayoutsModule {
}
