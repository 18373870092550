import { Component, OnInit } from '@angular/core';
import { Menu } from '@core/models/menu';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
/* La clase SidebarComponent es responsable de administrar los menús y las rutas activas en una barra lateral
componente. */
export class SidebarComponent implements OnInit {
  menus: Menu[] = [
    { title: 'Licitaciones', path: '/tenders' },
    {
      title: 'Configuración',
      children: [
        { title: 'Perfiles', path: '/profiles' },
        { title: 'Árbol', path: '/trees' },
        { title: 'Categorías', path: '/categories' },
        { title: 'Patrones', path: '/patterns' },
      ],
    },
    { title: 'Usuarios', path: '/users' },
  ];

  events: string[] = [];
  opened: boolean;
  title: string;

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {}


  /**
   * La función activeRoute comprueba si un nombre de ruta dado está actualmente activo en la URL del enrutador.
   * @ param {string} routename: el parámetro routename es una cadena que representa el nombre de un
   * ruta.
   * @ devuelve un valor booleano.
   */
  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

}
