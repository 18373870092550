
/* La clase ContentLayoutComponent es responsable de administrar los menús y la autenticación de usuarios en un
Aplicación angular. */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from '@core/models/menu';
import { User } from '@modules/users/state/user.model';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit {
  events: string[] = [];
  opened: boolean;
  title: string;
  userLogin: User;

  menus: Menu[] = [];

  constructor(private router: Router) {
    this.opened = true;
  }


/**
 * La función ngOnInit inicializa la matriz de menús con varios elementos de menú según el rol del usuario.
 */
  ngOnInit(): void {
    this.userLogin = JSON.parse(localStorage.getItem('user'));
    this.menus = [
      { title: 'Licitaciones', rol: ['ADMIN', 'USER'], path: '/tenders' },
      { title: 'Alertas Licitaciones', rol: ['ADMIN'], path: '/tenders/alerts' },
      { title: 'Alertas Adjudicadas', rol: ['ADMIN'], path: '/tenders/alerts-awardee' },
      { title: 'Permisos', rol: ['ADMIN'], path: '/permits' },
      {
        title: 'Mis Licitaciones',
        rol: ['ADMIN', 'USER'],
        path: '/tenders/mis-licitaciones/' + this.userLogin._id,
      },
      {
        title: 'Lic. Gestionadas',
        rol: ['ADMIN'],
        path: '/tenders/licitaciones-gestionadas',
      },
      { title: 'Perfiles', rol: ['ADMIN'], path: '/profiles' },
      { title: 'Árbol', rol: ['ADMIN'], path: '/trees' },
      { title: 'Categorías', rol: ['ADMIN'], path: '/categories' },
      { title: 'Patrones', rol: ['ADMIN'], path: '/patterns' },
      { title: 'Usuarios', rol: ['ADMIN'], path: '/users' },
      {
        title: 'Entes Contratantes',
        rol: ['ADMIN'],
        path: '/contracting-entities',
      },
      {
        title: 'Adjudicatarios',
        rol: ['ADMIN'],
        path: '/awardees',
      },
      {
        title: 'Próximos vencimientos',
        rol: ['ADMIN'],
        path: '/next-maturities',
      },
      {
        title: 'Cambiar Contraseña',
        rol: ['ADMIN', 'USER'],
        path: '/change-password',
      }
    ];
  }

  getRoleAdmin(roles: string[]): boolean {
    const rol = this.userLogin?.rol?.[0] || 'ADMIN';
    return roles.includes(rol);
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  logOut(): void {
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
  }
}
