import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';


/* La clase AuthInterceptor es un interceptor HTTP angular que agrega un encabezado de autorización con un
token portador a las solicitudes salientes y redirige a la página de inicio de sesión si falta el token. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

/**
  * La función de intercepción verifica si hay un token de acceso en el almacenamiento local, y si no,
  * redirige a la página de inicio de sesión; de lo contrario, agrega el token de acceso a los encabezados de solicitud y
  * continúa con la solicitud.
  * @ param request: el parámetro `request` es una instancia de la clase `HttpRequest`, que
  * representa una solicitud HTTP realizada por el cliente.
  * @ param {HttpHandler} next - El parámetro `next` es una instancia de la clase `HttpHandler`. Él
  * representa el siguiente controlador en la cadena que procesará la solicitud.
  * @ devuelve un Observable de tipo HttpEvent<desconocido>.
  */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const {accessToken} = JSON.parse(localStorage.getItem('token') || '{}');
   
    const registerCompany = this.router.url.includes('auth/register-company')
    const registerUser = this.router.url.includes('auth/register-user')

    if (!accessToken && !registerCompany && !registerUser) {
      this.router.navigateByUrl('/auth/login');
      localStorage.clear();
      return next.handle(request);
    }
    const headers = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${accessToken}`),
    });

    return next.handle(headers);
  }
}
