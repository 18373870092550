/* La clase SessionService administra las sesiones de los usuarios, incluida la actualización del nombre del usuario, el inicio de sesión,
guardar datos de la empresa y recuperar información de la empresa. */
/* La clase SessionService es responsable de administrar las sesiones de usuario, incluida la actualización del usuario.
nombre, iniciar sesión, guardar datos de la empresa y recuperar información de la empresa. */
import { SessionStore } from './session.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(private sessionStore: SessionStore, private http: HttpClient) {}

 /* El método `updateUserName` se encarga de actualizar el nombre del usuario en la sesión. Él
 toma un nuevo nombre como parámetro y actualiza la propiedad del nombre en el almacén de sesiones. */
  updateUserName(newName: string): void {
    this.sessionStore.update({ name: newName });
  }

  login(credentials): Observable<any> {
    return this.http.post(`${environment.api}/auth/login`, credentials);
  }

  saveCompany(data): Observable<any> {
    return this.http.post(`${environment.api}/companies`, data);
  }

  getCompany(id): Observable<any> {
    return this.http.get(`${environment.api}/companies/nif/${id}`);
  }

  changeUserPassword(email, oldPassword, newPassword): Observable<any> {
    return this.http.put(`${environment.api}/users/changepassword`, {email: email, oldPassword: oldPassword, newPassword: newPassword});
  }

  resetPassword(email): Observable<any>{
    return this.http.put(`${environment.api}/users/resetpassword`, {email: email});
  }

  restorePassword(token, password): Observable<any>{
    return this.http.put(`${environment.api}/users/restorepassword`, {token: token, password: password});
  }

  tokenExists(token): Observable<any>{
    return this.http.get(`${environment.api}/users/tokenexists/${token}`);
  }

}
