/* La clase `OrderBy` es una canalización de TypeScript que permite clasificar una matriz de objetos en función de un
propiedad y orden especificados. */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderBy implements PipeTransform {

  transform(array, orderBy, asc = false): any {

    if (!orderBy || orderBy.trim() === '') {
      return array;
    }

    
// ascendente
    if (asc) {
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item1[orderBy], item2[orderBy]);
      });
    } else {
      
// no ascendente
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item2[orderBy], item1[orderBy]);
      });
    }

  }

  orderByComparator(a: any, b: any): number {

    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      // No es un número, así que escribe en minúsculas la cadena para comparar correctamente
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
    } else {
      
// Analizar cadenas como números para comparar adecuadamente
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }

    return 0; 
    // igualar
  }
}
