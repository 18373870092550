
import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})

/* La clase AuthGuard se utiliza para proteger las rutas y evitar el acceso no autorizado mediante la implementación de varios
interfaces de guardia de ruta. */
export class AuthGuard
  implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {}


  get existsToken(): boolean {
    const { accessToken } = JSON.parse(localStorage.getItem('token') || '{}');
    return !!accessToken;
  }

/**
 * La función `canActivate` verifica si existe un token y redirige si no existe.
 * @ param {ActivatedRouteSnapshot} siguiente: el parámetro `siguiente` es una instancia de
 * `ActivatedRouteSnapshot` que representa el estado de la ruta que se activará a continuación.
 * @ aram {RouterStateSnapshot} state - El parámetro `state` es del tipo `RouterStateSnapshot` y
 * representa el estado actual del enrutador. Contiene información sobre la URL actual y cualquier
 * parámetros de consulta o fragmentos.
 * @ devuelve El método `canActivate` está devolviendo un `Observable<boolean | UrlTree>`, un
 * `Promesa<booleano | UrlTree>`, un `booleano` o un `UrlTree`.
 */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.clearAndRedirect(this.existsToken);
    return !!this.existsToken;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.clearAndRedirect(this.existsToken);
    return !!this.existsToken;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }

/**
 * La función comprueba si existe un token y redirige si no existe.
 * @ param {Ruta} ruta - El parámetro `ruta` es del tipo `Ruta` y representa la ruta actual
 * siendo navegado a.
 * @ param {UrlSegment[]} segmentos: una matriz de objetos UrlSegment que representan los segmentos del
 * URL.
 * @ devuelve El método `canLoad` devuelve un valor booleano.
 */
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.clearAndRedirect(this.existsToken);
    return !!this.existsToken;
  }


/**
 * La función borra el almacenamiento local y redirige a la página de inicio de sesión si no existe un token.
 * @ param existToken: un valor booleano que indica si existe o no un token.
 */
  clearAndRedirect(existsToken): void {
    if (!existsToken) {
      localStorage.clear();
      this.router.navigate(['auth/login'], {
        queryParams: { url: this.location.path() },
      });
    }
  }
}
