/* La clase AlertService proporciona métodos para mostrar diferentes tipos de alertas usando el
Biblioteca SweetAlert2. */
import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class AlertService {

  constructor() {
  }

  swalSuccess(message: string): void {
    Swal.fire('¡Buen Trabajo!', message, 'success');
  }

  swalError(message: string): void {
    Swal.fire('Opps...', message, 'error');
  }

  swalInfo(message: string): void {
    Swal.fire('Información', message, 'info');
  }

  swalWarning(message: string): void {
    Swal.fire('Cuidado!', message, 'warning');
  }

  swalConfirm(title: string, text: string, confirmButtonText = '¡Si, eliminar!'): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0f2d63',
      cancelButtonColor: '#a42626',
      cancelButtonText: 'Cancelar',
      confirmButtonText
    });
  }

}
