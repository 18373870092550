import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionService } from '@modules/auth/state/session.service';
import { Subscription } from 'rxjs';
import { AlertService } from '../../shared/alert.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isSubmitting = false;
  formSubmitted = false;
  allFieldsRequired = false;
  isFormEmpty = false; // Variable para verificar si el formulario está vacío
  private userLogin: any;

  private subscription: Subscription;

  constructor(
    private alertService: AlertService,
    private fb: FormBuilder,
    private sessionService: SessionService
  ) {
    this.form = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]
    });

    this.allFieldsRequired = false;
  }

  ngOnInit(): void {
    this.userLogin = JSON.parse(localStorage.getItem('user'));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSubmit() {
    if (this.form.invalid){
      return;
    }

    const currentPassword: string = this.form.get('currentPassword').value;
    const newPassword: string = this.form.get('newPassword').value;
    const confirmPassword: string = this.form.get('confirmPassword').value;

    if (newPassword !== confirmPassword) {
      this.alertService.swalError("La nueva contraseña no coincide con su confirmación");   
      return;
    }

    if (newPassword === currentPassword) {
      this.alertService.swalError("La nueva contraseña no puede ser igual a la actual");
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 20) {
      this.alertService.swalError("La contraseña debe tener entre 8 y 20 caracteres");
      return;
    }
    this.isSubmitting = true;

    this.subscription = this.sessionService.changeUserPassword(
      this.userLogin.email,
      currentPassword,
      newPassword).subscribe(response => {
        if(response.code == 200){
          this.alertService.swalSuccess("La contraseña ha sido cambiada con éxito");
          this.resetForm();
        } else {
          this.alertService.swalError(response.message);
        }
      },
      (error) => {
        this.alertService.swalError("Hubo un error en la solicitud");
        console.error("Error en la solicitud:", error);
        this.resetForm();
      }
    );
  }

  
  checkFields() {
    const currentPassword = this.form.get('currentPassword').value;
    const newPassword = this.form.get('newPassword').value;
    const confirmPassword = this.form.get('confirmPassword').value;

    if (currentPassword !== '' && newPassword !== '' && confirmPassword !== '') {
      this.allFieldsRequired = false;
    }
  }

  private resetForm() {
    this.isSubmitting = false;
    this.form.reset();
  }

  hasError(path: string, error: string): any {
    return this.form.hasError(error, path);
  }
}
