<li class="item" routerLinkActive="active">
  <a [routerLink]="[item.path]" *ngIf="!item.children; else dropdown">
    {{ item.title }}
  </a>
  <ng-template #dropdown>
    <a (click)="toggleMenu()">
      {{ item.title }}
      <mat-icon aria-hidden="false" aria-label="Example home icon">
        {{
          this.helpMenuOpen === 'out'
            ? 'keyboard_arrow_down'
            : 'keyboard_arrow_up'
        }}
      </mat-icon>
    </a>
    <ul class="child" [@slideInOut]="helpMenuOpen">
      <li class="item" *ngFor="let item of item.children">
        <a
          [routerLink]="[item.path]"
          routerLinkActive="child-active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ item.title }}
        </a>
      </li>
    </ul>
  </ng-template>
</li>
