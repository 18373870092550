/* La clase DecimalsPipe es una tubería personalizada en Angular que reemplaza las comas con puntos en una cadena. */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'decimals'
})
export class DecimalsPipe implements PipeTransform {

  transform(value: string, ...args): any {
    for (let i = 0; i < 3; i++) {
      value = value?.replace(',', '.');
    }
    return value;
  }

}
