import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
// @ts-ignore
export class HttpCacheService {
  private cache: {};

  constructor(private http: HttpClient) {
    this.cache = {};
  }

/**
 * La función realiza una solicitud GET a una URL específica y almacena en caché los datos de respuesta para una determinada
 * período de tiempo de espera.
 * @ {número} tiempo de espera: el parámetro de tiempo de espera es el tiempo máximo (en segundos) que la función
 * esperará una respuesta antes de considerarlo como un tiempo de espera.
 * @ param {string} url: el parámetro `url` es una cadena que representa la URL del recurso que
 * desea recuperar datos de.
 * @ ´param {any} callback - El parámetro `callback` es una función que será llamada con los datos
 * obtenido de la solicitud HTTP. Se utiliza para manejar los datos de respuesta y realizar cualquier
 * acciones con él.
 * @ param params: un objeto opcional que contiene pares clave-valor para enviar como parámetros de consulta en el
 * Solicitud HTTP.
 */
  get(timeout: number, url: string, callback: any, params = {}): void {
    timeout *= 1000;
    const sign = 'get' + url;
    if (this.cache[sign] && this.cache[sign].isValid()) {
      callback(this.cache[sign].getData());
    } else {
      this.http.get(url, {params})
        .subscribe((data) => {
          this.cache[sign] = new CacheUnit(timeout, data);
          callback(data);
        });
    }
  }
/**
   * La función realiza una solicitud POST a una URL específica, con una carga útil de datos opcional y cachés
   * la respuesta durante un período de tiempo de espera especificado.
   * @ param {número} tiempo de espera: el parámetro de tiempo de espera es el tiempo en segundos después del cual la solicitud
   * debe considerarse como tiempo de espera agotado si no se recibe respuesta.
   * @ param {cadena} url: el parámetro `url` es una cadena que representa la URL donde HTTP POST
   * se enviará la solicitud.
   * @ aram {any} [data=null] - El parámetro `data` es opcional y representa los datos que serán
   * enviado en la solicitud HTTP POST. Puede ser cualquier tipo de datos, como un objeto JSON o una cadena. Si
   * no se proporcionan datos, el valor de `data` se establecerá en `null`.
   * @ param {any} callback - El parámetro `callback` es una función que se llamará una vez que HTTP
   * La solicitud está completa. Se le pasarán los datos de respuesta como argumento.
   */
  post(timeout: number, url: string, data: any = null, callback: any): void {
    timeout *= 1000;
    const sign = 'post' + url;
    if (this.cache[sign] && this.cache[sign].isValid()) {
      callback(this.cache[sign].getData());
    } else {
      this.http.post(url, data)
        .subscribe((data) => {
          this.cache[sign] = new CacheUnit(timeout, data);
          callback(data);
        });
    }
  }

  put(timeout: number, url: string, data: any = null, callback: any): void {
    timeout *= 1000;
    const sign = 'put' + url;
    if (this.cache[sign] && this.cache[sign].isValid()) {
      callback(this.cache[sign].getData());
    } else {
      this.http.put(url, data)
        .subscribe((data) => {
          this.cache[sign] = new CacheUnit(timeout, data);
          callback(data);
        });
    }
  }

  /**
   * La función elimina datos de una URL específica, verifica si los datos están almacenados en caché y son válidos, y si
   * no, realiza una solicitud de eliminación HTTP y almacena en caché la respuesta.
   * @ param {número} tiempo de espera: el parámetro de tiempo de espera es un número que representa el tiempo en segundos
   * antes de que se agote el tiempo de espera de la solicitud.
   * @ param {cadena} url - El parámetro `url` es una cadena que representa la URL del recurso que
   * necesita ser borrado.
   * @ param {any} callback - El parámetro `callback` es una función que se llamará una vez que se elimine
   * la operación se ha completado. Se pasarán los datos devueltos por la solicitud de eliminación.
   */
  delete(timeout: number, url: string, callback: any): void {
    timeout *= 1000;
    const sign = 'delete' + url;
    if (this.cache[sign] && this.cache[sign].isValid()) {
      callback(this.cache[sign].getData());
    } else {
      this.http.delete(url)
        .subscribe((data) => {
          this.cache[sign] = new CacheUnit(timeout, data);
          callback(data);
        });
    }
  }

  clear(url: string): void {
    const sign: string = 'get' + url;
    this.cache[sign].timeout = 0;
  }
}

class CacheUnit {
  born: Date;
  timeout: number;
  data: any;

  constructor(timeout: number, data: any) {
    this.born = new Date();
    this.timeout = timeout;
    this.data = data;
  }

  isValid(): boolean {
    return (new Date().getTime() - this.born.getTime()) < this.timeout;
  }

  getData(): any {
    return this.data;
  }
}
