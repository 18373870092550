
/* Este código define una clase `SessionStore` que amplía la clase `Store` de la biblioteca Akita. */
import {Injectable} from '@angular/core';
import {Auth} from './auth.model';
import {EntityState, EntityStore, Store, StoreConfig} from '@datorama/akita';

export interface SessionState {
  token: string;
  name: string;
}

/**
 * El código anterior define una función y una clase de TypeScript que crean y administran el estado inicial de un
 * sesión en una aplicación.
 * @ devuelve La función createInitialState devuelve un objeto de tipo SessionState.
 */
export function createInitialState(): SessionState {
  return {
    token: '',
    name: ''
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'session'})
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(createInitialState());
  }

}

