/* La clase ServersService es un servicio Angular que recupera el nombre del servidor del entorno
configuración y proporciona un método para obtener la configuración regional del almacenamiento local. */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
// @ts-ignore
export class ServersService {
  constructor() { }
  serverName: string = environment.api;

  getLocale(): string {
    return JSON.parse(localStorage.getItem('auth_item'));
  }
}

export class Result {
  code: number;
  success: boolean;
  message: string;
  content: any;
}
