
/* La clase AddDatePipe es una tubería personalizada en Angular que agrega un número específico de días a un determinado
fecha. */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDate',
})
export class AddDatePipe implements PipeTransform {
  transform(value = '', ...args: unknown[]): unknown {
    const date = new Date(value);
    date.setDate(date.getDate() + Number(args[0]));
    return date;
  }
}
