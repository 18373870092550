
/* La clase IconsModule importa y exporta una selección de iconos de la biblioteca angular-feather. */
import {NgModule} from '@angular/core';

import {FeatherModule} from 'angular-feather';
import {
  Info,
  Menu,
  List,
  ShoppingCart,
  ChevronLeft,
  ChevronRight,
  MapPin,
  Watch,
  Phone,
  Plus,
  X,
  Check,
  Home,
  LogIn,
  Award,
  Box,
  User,
  Lock,
  LogOut,
  ShoppingBag,
  Package
} from 'angular-feather/icons';

// Selecciona algunos íconos (usa un objeto, no una matriz)
const icons = {
  Menu,
  ShoppingCart,
  ChevronLeft,
  ChevronRight,
  MapPin,
  Watch,
  Phone,
  Plus,
  X,
  Check,
  Home,
  LogIn,
  Award,
  Box,
  User,
  Lock,
  LogOut,
  ShoppingBag,
  Package,
  List,
  Info
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule {
}
