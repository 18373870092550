<!--<app-navbar></app-navbar>
<ng-sidebar-container>


  <ng-sidebar [(opened)]="opened" mode="push">
    <app-sidebar></app-sidebar>
  </ng-sidebar>

  <div ng-sidebar-content>
    <router-outlet></router-outlet>
  </div>
</ng-sidebar-container>-->
<app-loading></app-loading>

<mat-sidenav-container
  class="wrapper-container"
  [hasBackdrop]="false"
  id="wrapper"
>
  <mat-sidenav
    class="header"
    #sidenav
    mode="side"
    [(opened)]="opened"
    (opened)="events.push('open!')"
    (closed)="events.push('close!')"
    class="wrapper-navigation"
  >
    <div class="wrapper-user ">
      <i-feather name="user" class="color__icon__primary icon-user btn10"></i-feather>
      <span class="content-info-user ">
        <p >{{ userLogin.name }}</p>
        <p class="email ">{{ userLogin.email }}</p>
      </span>
    </div>

    <ng-container *ngFor="let item of menus">
      <a
        mat-button
        [routerLink]="[item.path]"
        [ngClass]="{ active: activeRoute('main') }"
        class="item-menu"
        *ngIf="getRoleAdmin(item.rol)"
      >
        <i-feather
          name="package"
          class="color__icon__primary icon"
      
        ></i-feather>
        <span >
          {{ item.title }}
        </span>
      </a>
    </ng-container>

    <div></div>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav__content">
    <mat-toolbar class="wrapper-header">
      <div class="content-navbar">
        <button
          mat-icon-button
          color="primary"
          (click)="sidenav.toggle()"
          aria-label="Despleglar menu"
        >
          <i-feather name="menu" class="color__icon__primary"></i-feather>
        </button>
        <img class="imagenLogo" src="../../../assets/favicons.ico">
        <div> 
           <a class="cerrarSesion" (click)="logOut()">
          Cerrar Sesión
          <i-feather name="log-out" class="color__icon__primary"></i-feather>
        </a>
        </div>
       
      </div>
    </mat-toolbar>
    
    <div class="wrapper-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
