// Este archivo se puede reemplazar durante la compilación usando la matriz `fileReplacements`.
// `ng build --prod` reemplaza `environment.ts` con `environment.prod.ts`.
// La lista de reemplazos de archivos se puede encontrar en `angular.json`.

export const environment = {
  production: false,
  api: 'http://api.dev.licitaciones.icc-e.org:9080',
  // api: 'http://localhost:3000',
  // api: 'https://api.licitaciones.icc-e.org',
  // einformaClient:'25fs611gsfdbk7xhhvfvh3g8cpw7o8g1dmekvpjl.api.einforma.com',
  // einformaSecret:'xGI3yHAb9C5X3qoDcwf-qOCDlEn1P2i8VfKYds70xlI'
};

/*
 * Para facilitar la depuración en el modo de desarrollo, puede importar el siguiente archivo
 * para ignorar los marcos de pila de errores relacionados con la zona, como `zone.run`, `zoneDelegate.invokeTask`.
 *
 * Esta importación debe ser comentada en modo producción porque tendrá un impacto negativo
 * en el rendimiento si se produce un error.
 */
// importar 'zone.js/dist/zone-error'; // Incluido con Angular CLI.
