import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
import {IconsModule} from './icons.module';
import {AlertService} from '@shared/alert.service';
import {MatBadgeModule} from '@angular/material/badge';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {DecimalsPipe} from '@shared/pipes/decimals.pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import {OrderBy} from '@shared/pipes/orderBy.pipe';
import { ActiveStatusPipe } from './pipes/active-status.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ResponsiblePipe } from './pipes/responsible.pipe';
import { AddDatePipe } from './pipes/add-date.pipe';
/* SharedModule es un módulo en TypeScript que declara y exporta varios componentes, tuberías y
módulos para su uso en otros módulos. */

@NgModule({
  declarations: [DecimalsPipe, OrderBy, ActiveStatusPipe, ResponsiblePipe, AddDatePipe],
  providers: [AlertService, {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCardModule,
        IconsModule,
        MatBadgeModule,
        MatExpansionModule,
        MatChipsModule,
        DecimalsPipe,
        NgxPaginationModule,
        OrderBy,
        ActiveStatusPipe,
        MatTooltipModule,
        ResponsiblePipe,
        AddDatePipe,
    ],
})
export class SharedModule {
}
