import { Component, Input, OnInit } from '@angular/core';
import { Menu } from '@core/models/menu';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

/* La clase PanelComponent es un componente de TypeScript que controla la animación y alternancia de un
menú de ayuda. */
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*',
          width: '100%',
        }),
      ),
      state(
        'out',
        style({
          opacity: '0',
          overflow: 'hidden',
          height: '0px',
          width: '100%',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
/* The PanelComponent class is a TypeScript component that manages the state of a help menu, allowing
it to be toggled between open and closed. */
export class PanelComponent implements OnInit {
  @Input() item: Menu;
  helpMenuOpen: 'out' | 'in' = 'out';

  constructor() {}

  ngOnInit(): void {}

  toggleMenu(): void {
    this.helpMenuOpen = this.helpMenuOpen === 'out' ? 'in' : 'out';
  }
}
