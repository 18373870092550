
/* La clase CoreModule es un módulo en una aplicación Angular que proporciona e importa servicios
relacionados con el almacenamiento en caché HTTP y la gestión del servidor. */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpCacheService, ServersService} from './services';


@NgModule({
  declarations: [],
  providers: [HttpCacheService, ServersService],
  imports: [
    CommonModule,
  ]
})

// @ts-ignore
export class CoreModule { }
