<div class="wrapper-content">
  <h1>Cambie su contraseña</h1>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="login mat-elevation-z8 ">
      <div class="logo">
  
        <img class="imagenLogo"  src="../../../../assets/favicons.ico" alt="logo" />
      </div>
  
      <p>Ingrese su contraseña</p>
      <mat-form-field appearance="legacy">
        <mat-label>Ingrese su contraseña actual</mat-label>
        <input required formControlName="currentPassword" id="currentPassword"  matInput type="password" />
    <mat-icon matSuffix>vpn_key</mat-icon>
  
        <mat-error *ngIf="hasError('currentPassword', 'required')">
          Ingrese contraseña
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="legacy">
          <mat-label>Ingrese su nueva contraseña</mat-label>
          <input required formControlName="newPassword" id="newPassword"  matInput type="password" />
      <mat-icon matSuffix>vpn_key</mat-icon>
  
          <mat-error *ngIf="hasError('newPassword', 'required')">
            Ingrese su nueva contraseña
          </mat-error>
        </mat-form-field>
  
        <mat-form-field appearance="legacy">
          <mat-label>Confirme su nueva contraseña</mat-label>
          <input required formControlName="confirmPassword" id="confirmPassword" matInput type="password" />
      <mat-icon matSuffix>vpn_key</mat-icon>
  
          <mat-error *ngIf="hasError('confirmPassword','required')">
            Confirme la nueva contraseña
          </mat-error>
        </mat-form-field>
      <button type="submit"  #changePasswordButton >Restablecer Contraseña</button>
    </form>
     
</div>