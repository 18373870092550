/* La clase ResponsiblePipe es una canalización personalizada en Angular que transforma una matriz de objetos User por
extrayendo una propiedad específica basada en el argumento proporcionado. */
import { Pipe, PipeTransform } from '@angular/core';
import {User} from '@modules/users/state/user.model';

@Pipe({
  name: 'responsible'
})
export class ResponsiblePipe implements PipeTransform {

  transform(responsible: User[], ...args: string[]): unknown {
    return responsible.map(responsable => responsable[args[0]]);
  }

}
