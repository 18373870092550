import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NG_ENTITY_SERVICE_CONFIG} from '@datorama/akita-ng-entity-service';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {AkitaNgRouterStoreModule} from '@datorama/akita-ng-router-store';
import {environment} from '@env/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutsModule} from '@layouts/layouts.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SidebarModule} from 'ng-sidebar';
import {AuthInterceptor} from '@core/interceptors/auth.interceptor';
import { FormsModule } from '@angular/forms';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { es_ES } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import {CoreModule} from '@core/core.module';
import {TreeviewModule} from 'ngx-treeview';


registerLocaleData(es);
/* La clase AppModule es el módulo principal de la aplicación, responsable de importar y configurar
varios módulos, proveedores y componentes. */

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule.forRoot(),
    BrowserAnimationsModule,
    LayoutsModule,
    HttpClientModule,
    SidebarModule.forRoot(),
    FormsModule,
    CoreModule,
  ],
  providers: [
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: {baseUrl: environment.api},
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: NZ_I18N, useValue: es_ES }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
