import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from '@layouts/content-layout/content-layout.component';
import { AuthLayoutComponent } from '@layouts/auth-layout/auth-layout.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { ChangePasswordComponent } from '@modules/change-password/change-password.component';
/* La clase AppRoutingModule define la configuración de enrutamiento para la aplicación. */

const routes: Routes = [
  { path: '', redirectTo: '/tenders', pathMatch: 'full' },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'patterns',
        loadChildren: () =>
          import('./modules/pattern/pattern.module').then(m => m.PatternModule),
      },
      {
/* SharedModule es un módulo en TypeScript que declara y exporta varios componentes, tuberías y
módulos para su uso en otros módulos. */
        path: 'categories',
        loadChildren: () =>
          import('./modules/category/category.module').then(
            m => m.CategoryModule,
          ),
      },
      {
        path: 'trees',
        loadChildren: () =>
          import('./modules/tree/tree.module').then(m => m.TreeModule),
      },
      {
        path: 'profiles',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'tenders',
        loadChildren: () =>
          import('./modules/tender/tender.module').then(m => m.TenderModule),
      },
      {
        path: 'contracting-entities',
        loadChildren: () =>
          import('./modules/contracting-entity/contracting-entity.module').then(
            m => m.ContractingEntityModule,
          ),
      },
      {
        path: 'permits',
        loadChildren: () =>
          import('./modules/permits/permits.module').then(m => m.PermitsModule),
      },
      {
        path: 'awardees',
        loadChildren: () =>
          import('./modules/enterprise-contract/enterprise-contract.module').then(m => m.EnterpriseContractModule),
      },
      {
        path: 'next-maturities',
        loadChildren: () =>
          import('./modules/next-maturitie/next-maturities.module').then(m => m.NextMaturitiesModule),
      },
      {
        path: 'change-password',
        
        component: ChangePasswordComponent,
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
