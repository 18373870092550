/* La clase DecimalsPipe es una tubería personalizada en Angular que reemplaza las comas con puntos en una cadena. */
/* La clase ActiveStatusPipe es una tubería personalizada en Angular que transforma una matriz de TenderStatus
objetos y devuelve el nombre del primer objeto con una propiedad activa establecida en verdadero, o 'No
apuntando' si no se encuentra ningún objeto activo. */
import { Pipe, PipeTransform } from '@angular/core';
import { TenderStatus } from '@core/models/tender-status';

@Pipe({
  name: 'activeStatus',
})
export class ActiveStatusPipe implements PipeTransform {
  transform(status: TenderStatus[], ...args: unknown[]): unknown {
    return status.find(state => state?.active)?.name || 'No asignada';
  }
}
